.notice {
    padding: 20px;
    border-radius: $borderRadius;
    background: $lightGrey;
    color: $grayColor;
    
    &.notice-info {
        background: rgba(42, 141, 240, 0.05); 
        color: $accentOneColor;
    }
    
    &.notice-warning {
        background: rgba(255, 138, 0, 0.05);
        color: $accentTwoColor;
    }
    
    &.notice-error {
        background: rgba(222, 98, 109, 0.05); 
        color: $redColor;
    }
    
    &.notice-success {
        background: rgba(46, 204, 113, 0.05);
        color: $greenColor;
    }
    
}