:root {
    --f7-button-font-weight: 500;
    --f7-button-small-font-weight: 500;
    --f7-segmented-strong-button-font-weight: 500;
    --f7-segmented-strong-bg-color: #{$lightGrey};
    // --f7-segmented-strong-button-active-bg-color: $secondaryColor;
    --f7-segmented-strong-button-active-box-shadow: none;
    --f7-segmented-raised-divider-color: transparent;
    --f7-segmented-strong-button-active-font-weight: 500;
    --f7-button-small-outline-border-width: 1px;
    --f7-button-outline-border-width: 1px;
    --f7-button-border-radius: #{$borderRadius};
    --f7-button-height: 45px;
    --f7-button-small-height: 32px;
    --f7-button-text-transform: capitalize;
    --f7-button-small-text-transform: var(--f7-button-text-transform);
    --f7-button-large-text-transform: var(--f7-button-text-transform);
}

.ios {
    --f7-button-height: 36px;
}

.button.button-gradient {
    background: linear-gradient(270deg, #16284E 0%, #203C76 100%);
}

.button-secondary {
    background: #EBEFF8;
    color: $secondaryColor;
    border: none;
}