#view-workout {
    
    padding-bottom: 20px;
    
    .page-content {
        padding: 0;
    }
    
    #logo  {
        width: 200px;
        margin: 40px auto 5px;
        
        svg {
            width: 100%;
            height: auto;
        }
    }
    
    .block-title {
        margin-left: 0;
        text-align: center;
    }
    
    .card {
        max-width: 500px;
        margin: auto;
        border: none !important;
        box-shadow: 0px 1px 4px rgba(13, 19, 47, 0.15);
    }
    
    .card-header.text-align-center {
        flex-flow: row;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        padding-top: 30px;
    }
    
    .card-header {   
        flex-flow: column;
        
        .meta {
            color: $grayColor;
            font-weight: 500;
            font-size: 14px;
        }
        
    }
    
    .card-content {
        max-height: 30vh;
        overflow: auto;        
    }
    
    .card-footer {
        padding-top: 20px;
        flex-flow: column;
        
        .button {
            width: 100%;
        }
        
    }
    
    .sets-display {
        margin-top: 0;
    }
    
    .movements-list {
        
        .mov-name .link {
            display: none !important;
        }
        
    }
    
}

.popup#view-workout-sign-in {
    
    .block-footer {
        display: none !important;
    }
    
}