.popup,
.panel {
    
    .page {
        padding-top: $ios-margin-top;
    }
    
    .modal-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 5px;
        
        .icon {
            font-size: 26px;
            color: $grayColor;
            line-height: 1;
            padding: 2px;
            
            &:hover {
                opacity: 0.8;
            }
            
        }
        
        .button {
            min-width: auto;
            height: auto;
            background: transparent !important;
            margin-left: 10px;
        }
        
        .menu-item {
            width: auto;
            min-width: auto;
            height: auto;
            
            .menu-dropdown-content {
                margin-top: 0px;
                padding-left: 7px;
                padding-right: 7px;
            }
            
            .menu-item-content:after {
                display: none;
            }
            
            .menu-dropdown-link {
                font-size: 14px;
                min-height: 40px;
                min-width: auto;
                
                font-weight: 400;
                
                &.active-state:before {
                    display: none !important;
                }
                
            }
            
        }
        
    }
    
}

.hidden {
    display: none !important;
}

.horizontal-scroll {
    white-space: nowrap;
    overflow: auto;
    
    > * {
        display: inline-block;
        margin-right: 20px;
    }
    
}