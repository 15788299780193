$signInUpMaxWidth: 400px;

:root {
    --f7-login-screen-bg-color: transparent;
}

.page.signin-signup {
    
    > .page-content {
        max-width: $signInUpMaxWidth;
        padding: 0;
    }
    
    #logo {
        
        text-align: center;
        margin-top: 60px;
        
        svg {
            max-width: 70%;
            height: 60px;
        }
    }
    
    #slogan {
        
        text-align: center;
        margin-bottom: 50px;
        
        svg {
            max-width: 70%;
            fill: $grayColor;
        }
        
    }
    
    .login-screen {
        
        position: static;
        height: auto;
        
        .item-inner {
            display: block;
        }
        
        .button {
            width: 100%;
            margin-top: 30px;
        }
        
    }
}

#forgot-password-sheet {
    
    .page-content{
        max-width: $signInUpMaxWidth;
        margin: auto;
    }
    
    .item-inner {
        justify-content: center;
    }
    
}

