:root {
    --f7-card-header-border-color: transparent;
    --f7-card-footer-border-color: transparent;
    --f7-card-box-shadow: 0px 4px 26px rgba(206, 211, 215, 0.28);
    --f7-card-header-font-weight: 600;
    --f7-card-header-padding-vertical: 14px;
    --f7-card-header-font-size: 18px;
}

.card {
    
    .card-header {
        
        word-break: break-word;
        align-items: flex-start;
        
        .float-right {
            
            .button {
                display: inline-block;
                margin-left: 5px;
                padding: 4px;
                line-height: 1;
                height: 28px;
                
                i {
                    font-size: 20px;
                }
                
            }
        }
        
    }
    
}