:root {
    --f7-popup-tablet-height: 700px;
}

.popup {
    
    .page-content {
        
        > .block:first-child {
            z-index: 1000;
        }
        
    }

}