#account-menu-panel {
    
    .page-content {
        
        > .block:last-child {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
        }
        
    }
    
}

.account-settings.tab {
    
    > .page-content {
        
        .list .item-content {
            padding: 0;
        }
        
    }
    
}

.tab#account-info {
    
    iframe {
        margin-top: 20px;
        width: 100%;
        height: calc(100vh - 100px);
        border: none;
        margin-bottom: 60px;
    }
    
}


//smartphones
@media (max-width: 768px) {
    
    .tab#account-info {
        
        iframe {
            margin-top: 20px;
            width: 100%;
            height: calc(100vh - 100px - env(safe-area-inset-top)*3);
            border: none;
        }
        
    }
     
}

