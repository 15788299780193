:root {
    --f7-searchbar-input-bg-color: #fff;  
    --f7-searchbar-inline-input-font-size: 16px;  
    --f7-input-height: 40px;
    --f7-input-outline-border-color: #{$lightGrey};
    --f7-stepper-border-width: 1px;
    --f7-stepper-height: 36px;
    --f7-stepper-border-radius: #{$borderRadius};
    --f7-searchbar-inline-input-font-size: 14px;
    --f7-input-font-size: 14px;
    --f7-input-outline-border-radius: #{$borderRadius};
    --f7-input-outline-focused-border-color: #CBDBFF;
    --f7-checkbox-icon-color: #fff;
}

input {
    
    height: var(--f7-input-height);
    line-height: var(--f7-input-height);
    
    &[disabled] {
        border: none !important;
        opacity: 1;
    }
    
    &[type="checkbox"] {
        appearance: none; 
        position: relative;
        cursor: pointer;
        border-width: 2px !important;
        width: 24px;
        height: 24px;
        padding: 0 !important;
        
        &:checked {
            background: $primaryColor;
            border-color: $primaryColor;
        }
        
        &:checked:before {
            content: "\e5ca";
            display: block;
            font-size: 16px;
            font-family: "Material Icons";
            font-weight: 700;
            width: 100%;
            height: 100%;
            color: #fff;
            line-height: 20px;
        }
    }
    
}

.input {
    
    input, 
    textarea,
    select {
        padding: 8px;
        padding: 8px;
        border: 1px solid var(--f7-input-outline-border-color);
        border-radius: var(--f7-input-outline-border-radius);
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    input[type=number] {
        -moz-appearance: textfield;
    }
    
    > .input-info {
        margin-top: 4px;
    }
    
    &:after {
        display: none !important;
    }
    
}

.input,
.text-editor {
    
    &[data-count] {
        
        position: relative;
        
        &:before {
            position: absolute;
            content: attr(data-count);
            bottom: -15px;
            right: 0;
            font-size: 10px;
            color: $grayColor;
            line-height: 1;
        }
        
    }
    
}

.input-focused {
    
    > input, 
    > textarea,
    select {
        border-color: var(--f7-input-outline-focused-border-color);
    }
    
}

.aurora {
    
    .input-focused {
        
        &:not(.input-outline):after {
            display: none !important;
        }
        
    }

}

.time-input {
    
    position: relative;
    display: flex;
    justify-content: flex-start;
    
    > div {
        
        width: 40px;
        position: relative;
        text-align: center;
        line-height: 1;
        
        &:last-child {
            margin-left: 5px;
        }
        
        input {
            width: 40px !important;
            height: 40px !important;
            background: #fff;
            border: 1px solid $lightGrey;
            display: inline-block;
            text-align: center;
            z-index: 10;
            border-radius: $borderRadius;
            
            &:focus {
                border-color: var(--f7-input-outline-focused-border-color);
            }
        }
        
        span {
            color: $grayColor;
            font-size: 10px;
            display: inline-block;
            text-transform: uppercase;
        }
           
    }
    
    &.disabled {
        
        > div {
            
            padding: 0;
            
            &:first-child input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            
            &:last-child input {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            
            input {
                font-weight: 600;
            }
            
        }
        
    }
}

.stepper-button, 
.stepper-button-minus, 
.stepper-button-plus,
.stepper-input-wrap {
    border-color: $lightGrey;
}

.searchbar {
    
    border-radius: $borderRadius;
    border: 1px solid $lightGrey;
    
    input {
        font-size: 14px;
        padding: 2px 3px;
        
    }
    
    .searchbar-icon:after {
        font-size: 16px;
    }
    
    &.searchbar-focused {
        border-color: var(--f7-input-outline-focused-border-color) !important;
    }
    
}

.input-action {
    
    width: 100%;
    display: flex;
    
    > .input {
        flex: 1 0 auto;
        
        input {
            height: 100%;
            line-height: 1;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
        
    }
    
    > .button {
        flex: 0 0 auto;
        height: auto;
        align-items: center;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    
}