:root {
	--f7-toolbar-height: 70px;
	--f7-tabbar-link-inactive-color: rgba(#{$primaryColorRed}, #{$primaryColorGreen}, #{$primaryColorBlue}, 0.3);
	--f7-toolbar-font-size: 14px;
	--f7-toolbar-bg-color-rgb: transparent;
	--f7-toolbar-bg-color: transparent;
}

.toolbar {
    
    #logo {
        
        width: 90px;
        display: block;
        position: absolute;
        top: calc(var( --f7-block-margin-vertical ) + 10px);
        transition: all 300ms ease-in-out;
        
        > svg {
            width: 100%;
            height: auto;
            
            g {
                // fill: #fff;
            }
            
            &:hover {
                
                
                
            }
        }
        
        &:hover {
            opacity: 1;
        }
        
    }
	
	.tab-link-highlight {
		display: none;
	}
    
    &.secondary {
        
        height: 40px;
        
        .toolbar-inner {
            overflow-y: visible; 
        }
        
        .tab-link {
            min-width: 50px;
            
            > span {
                line-height: 1;
            }
        }
                      
        .tab-link-active {
            background: $lightGrey;
            border-radius: 5px;   
        }
        
    }
    
    &.tabular {
        
        height: 40px;
        
        .toolbar-inner {
            justify-content: space-evenly; 
            position: relative;
            
            &:before {
                content: '';
                width: 100%;
                height: 2px;
                background: $lightGrey;
                position: absolute;
                bottom: 0;
                left: 0;
            }
            
            .link {
                background: transparent !important;
                border-bottom: 2px solid $lightGrey;
                flex: 1;
                z-index: 100;
                border-radius: $borderRadius;  
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                opacity: 1 !important;
                transition: margin 200ms ease-in-out;
                
                &.tab-link-active {
                    border: 2px solid $lightGrey;
                    border-bottom-color:#fff;
                    margin-left: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left));
                    margin-right: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left));
                }
                
            }
            
        }
        
    }
	
}

#main-toolbar.toolbar.toolbar-bottom {
    position: fixed;
	bottom: 0;
	width: 100%;
	left: 0;
	background: #fff !important;
	border-radius:0;
	border: none !important;
	box-shadow: 0px -8px 32px rgba(70, 96, 135, 0.1);

	&:after {
		display: none !important;
	}

	>.toolbar-inner {
		padding: 0 30px;
		
		>.link {
			
			.icon {
				font-size: $mainToolbarIconFontsize;
			}
			
			> span {
				font-size: 14px;
				display: block;
                margin-top: 6px;
			}
            
		}
		
		.tab-link-active {
			color: var(--f7-theme-color);
		}
	}
}

//desktop
@media (min-width: 768px) {

	#main-toolbar.toolbar.toolbar-bottom {
		left: 0;
		border-radius: 0;
		width: $toolbarWidthDesktop;
		height: 100%;
		position: fixed !important;
		bottom: 0;

		.toolbar-inner {
			flex-direction: column;
			justify-content: center;
			padding: 0;

			>a {
				flex-basis: 140px;

				.icon {
					font-size: mainToolbarIconFontsizeDesktop;
				}

			}
		}

	}

}

//smartphones
@media (max-width: 768px) {
    
    .toolbar {
        
        #logo {
            display: none !important;
        }
        
    }
     
}