$circleSize: 18px;
$checkLineWidth: 2px;
$movNameHeight: 60px;
$movPaddingBottom: 30px;

.movements-list {
    
    margin: 30px 0 0 !important;
    
    .item-content {
        padding-left: 0;
    }
    
    .item-inner {
        
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 0;
        position: relative;
        
        .movement.row {
            
            &:before {
                content: counter(list-item)".";
                position: absolute;
                font-weight: 600;
                left: 0px;
            }
            
            > .mov-name {
                overflow: auto;
                max-height: $movNameHeight;
                font-weight: 600;  
                padding-left: 20px;
                font-size: 15px;              
            }
            
            .log-param {
                text-align: center;
                display: flex;
                justify-content: flex-end;
                
                > div {
                    flex-basis: 40px;
                    
                    &:last-child {
                        margin-left: 5px;
                    }
                    
                }
                
                .input {
                    display: inline-block;
                }
                
                span {
                    color: $grayColor;
                    font-size: 10px;
                    display: block;
                    text-transform: uppercase;
                }
                
                .time-input {
                    display: flex;
                }
                
            }
            
        }
        
        .rest.row {
            
            > div:first-child {
                padding-left: 20px;
            }
            
            .list-rest-time {
                
                > .time-input {
                    justify-content: flex-end;
                }
                
            }
        }
        
        .row.actions {
            
            > .col {
                text-align: right;
            }
            
            .button:last-child {
                margin-left: 8px;
            }
        }
        
        .row {
            
            width: 100%;
            margin-bottom: 10px;
            
            input {
                font-size: 12px !important;
                text-transform: uppercase;
                padding: 0;
                text-align: center;
            }
            
            &:last-child {
                padding-bottom: $movPaddingBottom;
            }
            
        }
        
    }
    
    li {        
        .sortable-handler {
        }
        
    }
    
    &.sortable-enabled {
        
        li {
            padding-right: 20px;
        }
        
    } 
    
}

.sets-display {
    
    padding: 0;
    
    .toolbar-inner {
        padding: 0 20px !important;
    }
    
    .item-content {
        
        padding: 0;
        
        .item-inner {
            padding: 0;
        }
        
        .movement.row {
            
            min-height: 65px;
            
            &:before {
                left: 20px;
            }
            
            .mov-name {
                padding-left: 40px;
                
                a {
                    font-size: 11px;
                    display: block;
                }
                
            }
            
            .mov-check {
                
                text-align: center;
                padding-top: 10px;
                
                input {
                    margin: 0;
                }
                
            }
            
            > div:last-child {
                padding-right: 10px;
            }
            
        }
        
    }
    
    $_restTimerColor: $grayColor;
    $_activeColor: $accentTwoColor;
    .rest-timer {
        
        width: 100%;
        position: relative;
        margin-bottom: 40px;
        margin-top: 10px;
        font-size: 14px;
        
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            width: 100%;
            height: 1px;
            background: $_restTimerColor;
        }
        
        > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #fff;
            position: absolute;
            left: 50%;
            padding: 0 15px;
            transform: translate(-50%, -50%);
            color: $_restTimerColor;
            
            .time-input {
                
                opacity: 1 !important;
                margin-left: 5px;
                
                > div {
                    width: auto;
                    margin: 0;
                    white-space: nowrap;
                    
                    &:first-child:after {
                        content: ":";
                        margin-left: 8px;
                    }
                    
                    span {
                        font-size: 14px;
                        color: $_restTimerColor;
                        text-transform: lowercase;
                    }
                }
                
                input {
                    border: none !important;
                    color: $_restTimerColor;
                    width: 30px !important;
                    padding: 0;
                    
                    &[disabled] {
                        -webkit-text-fill-color: $grayColor;
                    }
                    
                }
            }
                
        }
        
    }
    
    &.mode-execute {
        
        li:first-child:not(.checked) {
            
            .mov-name {
                color: $_activeColor;
            }
            
        }
        
        li.checked:not(.timer-start) + li:not(.checked) {
            
            .mov-name {
                color: $_activeColor;
            }
            
        }
        
        li.timer-start {
            
            .rest-timer {
                
                transform: scale(1.5);
                
                &:before {
                    background: $_activeColor;
                }
                
                * {
                    color: $_activeColor !important;
                    -webkit-text-fill-color: $_activeColor !important;
                }
                
            }
            
        }
        
    } 
    
}

.sets-builder,
.sets-display {
    
    .toolbar.secondary {
        height: 30px;
    }
    
    .toolbar-inner {
        
        .tab-link {
            padding: 0 12px;
        }
        
    }
    
}

.tab > .sets-builder {
    margin-top: 32px;
}

#popover-share-link,
#popover-pl-share-link {
    
    .input-action {
        height: 35px;
        
        .icon {
            font-size: 18px;
        }
        
    }
    
    input {
        font-size: 12px !important;
    }
    
}