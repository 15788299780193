#planner {
    
    .page-content {
        
        >.block {
            padding-left: 0;
            padding-right: 0;
        }
        
    }
    
    #month-year-wrapper {
        z-index: 1000;
        margin-bottom: 10px;
        text-align: center;
        
        .menu-item-dropdown {
            background: transparent;
            margin: 0 7px;
            line-height: 20px;
            
            .menu-item-content {
                padding: 0;
            }
            
            .menu-item-content:after {
                display: none !important;
            }
            
        }
        
        .button {
            background: none !important;
            padding-top: 5px;
            line-height: 1;
            
            &:hover {
                opacity: 0.8;
            }
            
            .icon {
                font-size: 20px;
            }
            
            &:last-child {
                margin-left: 6px;
            }
            
        }
        
    }
    
    #date-selector {
        
        height: 90px;
        
        .toolbar-inner {
            
            align-items: flex-start;
            
            .link {
                background: #fff;
                margin: 0 5px;
                display: inline-block;
                width: 55px;
                height: 70px;
                padding: 12px 0 0;
                text-align: center;
                border-radius: $borderRadius;
                box-shadow: 0px 4px 26px rgba(206, 211, 215, 0.28);
                position: relative;
                
                span {
                    color: $grayColor;
                    display: block;
                    font-size: 16px;
                    margin-bottom: 12px;
                    margin-left: 0;
                    line-height: 1;
                    
                    &.day {
                        color: $primaryColor;
                        font-weight: 400;
                        margin: 0 !important;
                    }
                    
                }
                
                &.has-sessions:before {
                    content: "";
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    background: $accentTwoColor;
                }
                
                &.is-weekend {
                    background: $lightGrey;
                }
                
                &.active {
                    background: $accentOneColor !important;
                    
                    * {
                        color: #fff !important;
                    }
                    
                }
                
                &.completed:before {
                    background: $greenColor !important;
                }
                
            }
            
        }
        
    }
    
    #timeline-sessions {
        
        margin-top: 10px;
        position: relative;
        
        > .row {
            overflow: auto;
        }
        
        .day-col {
            
            margin-bottom: 20px;
            padding: 0 3px;
            overflow: hidden;
            
            > .segment {
                box-shadow: $primaryDropShadow;
            }
            
            &.active {
                
                .segment {
                    border: 1px solid $accentOneColor;
                } 
                
            }
            
            .session {
                
                margin: 20px 0 30px;
                position: relative;
                
                &:first-child {
                    
                    .move-up {
                        display: none;
                    }
                    
                }
                
                &:last-child {
                    
                    margin-bottom: 0;
                    
                    .move-down {
                        display: none;
                    }
                    
                }
                
                > .card-header {
                    
                    background: $lightGrey;
                    padding-top: 6px;
                    padding-bottom: 6px;
                    margin-bottom: 15px;
                    border-radius: 4px;
                    min-height: auto;
                    
                    .float-right {
                        display: block;
                        min-width: 100px;
                        text-align: right;
                        
                        .button:not(.color-red) {
                            color: $grayColor;
                        }
                        
                    }
                    
                }
                
                &.completed  > .card-header {
                     background: lighten($greenColor, 45%);
                    //color: $greenColor;
                }
                
                > .card-content {
                    
                    padding: 0 5px;
                    
                    > .workout {
                        
                        margin-bottom: 10px;
                        
                        > span:first-child {
                            display: inline-block;
                            width: 22px;
                            height: 22px;
                            background: $lightGrey;
                            margin-right: 10px;
                            padding: 4px;
                            text-align: center;
                            font-weight: 600;
                            border-radius: 4px;
                            font-size: 16px;
                        }
                        
                        > span:last-child {
                            font-weight: 500;
                        }
                        
                    }
                    
                }
                
                > .card-footer {
                    padding: 0 5px;
                }
                
            }
            
        }
        
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 50px;
            background: linear-gradient(0deg, rgba(0,0,0,0) 50%, rgba(249,249,249,1) 100%);
            top: 0;
            left: 0;
            z-index: 100;
        }
        
    }
    
}

#planner-write-session,
#planner-view-session {
    
    width: 500px;
    max-width: 100vw;
    
    #write-session-header {
        
        .block-title .button {
            margin-left: 5px;
            background: none !important;
            transform: translate(12px, -3px);
        }
        
        #change-date-input {
            width: 0px;
            height: 1px;
            visibility: hidden;
        }
        
    }
    
    .card {
        
        margin: 20px 0;
        box-shadow: $primaryDropShadow;
        
        .card-header {
            
            .float-left {
                
                max-width: calc(100% - 140px);
                
                .workout-info {
                    display: block;
                    font-size: 14px;
                    color: $grayColor;
                    margin: 10px 0;
                }
                
            }
            
            .float-right > .button {
                height: 100%;
            }
            
        }
        
        .card-content {
            
            padding-top: 0;
            
           .sets-display {
               padding-top: var(--f7-card-content-padding-horizontal);
           }
            
            .workout-description {
                
                ul, ol {
                    padding: 0;
                    padding-left: 20px;
                    margin: 0;
                }
                
            }
            
        }
        
    }
    
    .movements-list {
        
        .item-inner {
            padding-right: 0;
        }
        
    }
    
}

#planner-write-session {
    
    .workouts {
        
        .card:first-child {
            
            .move-up.button {
                display: none !important;
            }
            
        }
        
        .card:last-child {
            
            .move-down.button {
                display: none !important;
            }
            
        }
        
    }
        
}

#planner-view-session {
    
    .card {
        
        &.closed {
            
            .card-content {
                display: none;
            }
            
        }
        
        &.completed {
            
            .card-footer {
                display: flex;
            }
            
        }
        
    }
    
    .card-footer {
        
        padding-top: 20px;
        background: rgba(0,0,0, 0.02);
        
        .row {
            width: 100%;
            
            > div {
                margin-bottom: 10px;
            }
            
        }
        
        .time-input {
            
            > div {
                flex: 1;
            }
            
            input {
                width: 100% !important;
            }
            
        }
        
        .input, input {
            text-align: center;
        }
        
        .score-amrap {
            
            .col-50 {
                flex: 1;
                
                &:last-child {
                    margin-left: 5px;
                }
                
            }
            
        }
        
        .score-emom {
            
            input {
                float: left;
                margin: 0;
            }
            
            > span {
                float: left;
                margin: 2px 0 0 10px;
            }
            
        }
        
        textarea {
            background: #fff;
            font-size: 12px;
        }
        
    }
    
}

#new-record-popup {
    
    width: 500px;
    max-width: 100%;
    max-height: 330px;
    top: 50%;
    left: 50%;
    margin: 0 !important;
    transition: none !important;
    
    .page {
        padding: 0 !important;
    }
    
    &.modal-in {
       transform: translate(-50%, -50%) !important; 
       
       .icon {
           animation: gelatine 1s ease-in-out;
           animation-delay: 100ms;
       }
       
    }
    
    .block {
        
        text-align: center;
         
        > .icon {
            font-size: 60px;
            color: $accentOneColor;
            text-shadow: $primaryDropShadow;
        }
        
        #new-record-placeholder {
            font-size: 20px;
            margin-top: 20px;
            margin-bottom: 50px;
        }
        
    }
    
}

//desktop
@media (min-width: 768px) {
    
    #planner {
        
        #month-year-wrapper {
            text-align: left;
        }
        
    }

}

//mobile
@media (max-width: 768px) {
    
    #planner.tab {
        
        .page-content {
            
            padding-left: 0;
            padding-right: 0;
            
            >.block {
                padding-left: $paddingVertical;
                padding-right: $paddingVertical;
            }
            
        }
        
        #date-selector {
            
            height: 70px;
            
            .link {
                width: 45px;
                height: 50px;
                padding: 10px 0 0;
                
                span {
                    font-size: 14px;
                    margin-bottom: 5px;
                }
                
                &.has-sessions:before {
                    top: 4px;
                    right: 4px;
                }
                
            }
            
        }
        
    }

}