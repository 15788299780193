body,
.view > .page {
  background: $pageBackground !important;
}

#framework7-root {
    padding-top: $ios-margin-top;
}

.view > .page > .page-content,
#main-tabs > .tab > .page-content {
    
    max-width: 900px;
    margin:  auto;
    
    > .block {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
    }

}

.view > .page > .notice {
    text-align: center;
}

.preloader-backdrop {
    background: rgba(0,0,0, 0.04);
    opacity: 1;
}

hr {
    border: none;
    border-top: 1px solid $lightGrey;
}

.firebase-emulator-warning {
    display: none !important;
}

#logo {
    
    g {
        fill: $primaryColor;
    }
    
}

.info {
    color: $grayColor;
    font-size: 13px;
    font-style: normal;
}

a {
    color: #{$secondaryColor};
    font-weight: 500;
}

.help-popover {
    
    margin: 0 5px;
    
    i {
       font-size: 18px !important;
       color: $grayColor;
    }
    
}

.sheet-backdrop {
    z-index: 11001 !important;
}

.swipeout-actions-right {
    right: -1px;
}

//CookieYes
.cky-btn.cky-btn-custom-accept {
    width: 90%;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.cky-modal-close {
    width:  auto !important;
}

//desktop
@media (min-width: 768px) {
    
    .view > .page > .page-content,
    #main-tabs > .tab > .page-content {
        padding: 0 20px $paddingVertical $toolbarWidthDesktop+$paddingVertical;
    }
    
    .view > .page > .notice {
        margin-left: $toolbarWidthDesktop;
    }

}

@media (max-width: 768px) {
    
    .view > .page > .page-content,
    #main-tabs > .tab > .page-content {
        padding-left: $paddingVertical;
        padding-right: $paddingVertical;
    }

}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.5, 1.5);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes gelatine {
    0% { transform: scale(1, 1); }
    25% { transform: scale(0.8, 1.2); }
    50% { transform: scale(1.2, 0.8); }
    75% { transform: scale(0.95, 1.05); }
    100% { transform: scale(1, 1); }
}