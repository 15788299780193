:root {
    --f7-panel-backdrop-bg-color: rgba(0,0,0, 0.4);
    --f7-panel-right-width: 90%;
}

.panel {
    
    max-width: 300px;
    
    .panel-preloader {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 40px;
        height: 40px;
        z-index: 10000;
        transform: translate(-50%, -50%);
    }
    
}