.popover[data-select-name="source_filter"] .popover-angle {
    left: 20px !important;
}

#library.tab {
    
    #filter-area {
        
        .list {
            margin-bottom: 0;
        }
        
        .smart-select {
            
            background: none !important;
            margin-bottom: 0;
            max-width: 350px;
            
            .item-content {
                
                min-height: auto;
                
                .item-media {
                    padding: 0;
                }
                
                .item-inner {
                    justify-content: flex-start;
                    padding: 0;
                    min-height: auto;
                }
                
                .item-after {
                    margin: 0;
                    padding: 0;
                }
                
            }
            
            
        }
        
        padding-top: 2px;
        
    }
    
    #search-box {
        
        background: #fff;
        position: relative;
        color: $grayColor;
        
        .icon {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            font-size: 19px;
            color: $grayColor
        }
        
        input {
            padding-left: 40px;
            padding-right: 40px;
            color: $primaryColor;
            
            &::placeholder {
              color: var(--f7-searchbar-placeholder-color);
              opacity: 1;
            }
            
        }
        
        .input-clear-button {
            right: 8px;    
            color: $grayColor;
        }
        
    }
    
    .list {
        
        margin-top: 0;
        margin-bottom: 0;
        overflow: auto;
        
        .item-content {
            padding-left: 2px;
        }
        
        .swipeout-actions-right {
            right: -1px;
        }
        
    }
    
    @keyframes fadeNewlyAdded {
        from { background-color: $lightGrey; }
        to { background-color: rgba(255,255,255,0); }
    }
    #search-results {
        
        li.new-added {
            background: $lightGrey;
            animation: fadeNewlyAdded 2000ms;
            animation-fill-mode: forwards;
            animation-delay: 500ms;
        }
        
        .item-media {
            
            .icon {
                color: $accentOneColor;
            }
            
        }
        
    }
    
}

#public-library-popup {
    
    > .page .page-content {
        flex-flow: column;
        height: 100%;
    }
    
    
    #algolia-wrapper {
        
        margin: 0;
        height: calc(100% - 100px);
        
        >.list {
            margin: 0;
            overflow: auto;
            max-height: calc(100% - 100px);
            
            .item-content {
                padding: 0 5px;
                
                .item-media {
                    
                    .icon {
                        color: $accentOneColor;
                    }
                    
                }
                
                .item-inner {
                    
                    padding: 0;
                                        
                    .button {
                        flex: 0 30px;
                        height: 28px;
                        
                        .icon {
                            font-size: 20px;
                        }
                        
                    }
                    
                }
            }
            
        }
        
    }
    
    .ais-Pagination {
        position: absolute;
        right: 20px;
        bottom: 0;
        width: 200px;
    }
    
}

#add-edit-workout-popup {
    
    .block-title {
        margin-bottom: 10px !important;
    }
    
    input[type=number] {
        max-width: 70px;
        text-align: center;
    }
    
    .builder-configs {
        
        li {
            
            padding-left: 0;
            padding-right: 0;
            
            .item-content {
                padding-left: 0;
            }
            
            .item-inner {
                
                .row {
                    width: 100%;
                }
                
            }
        }
        
    }
    
}

#movement-popup,
#workout-popup,
#public-library-popup,
#public-library-item-details-popup {
    
    .block-title.block-title-large {
        text-overflow: unset;
        white-space: normal;
        margin: 0 0 8px;
    }
    
    .description {
        
        font-size: 16px;
        
        ul,ol {
            padding-left: 20px;
            margin: 0 0 10px;
        }
        
        br {
            margin: 0 0 10px;
        }
        
    }
    
    .timecap {
        margin-left: 5px;
    }
    
    .toolbar.tabular {
        margin-bottom: 40px;
    }
    
    .best-score-wrapper,
    .best-record-wrapper {
        
        text-align: center;
        font-size: 40px;
        font-weight: 500;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        
        .info {
            display: block;
            margin-bottom: 10px;
            text-transform: uppercase;
        }
        
    }
    
    #chart-wrapper {
        
        padding: 10px 0 ;
        background: #fff;
        box-shadow: 0px 4px 103px rgba(50, 50, 71, 0.01), 0px 4px 59px rgba(50, 50, 71, 0.06);
        
        .toolbar {
            height: 25px;
            margin-bottom: 20px;
            
            .toolbar-inner {
                justify-content: space-evenly;  
                padding: 0;
                
                .link {
                    color: $grayColor;
                    margin-right: 40px;
                    padding: 5px;
                    height: 100%;
                    
                    &.tab-link-active {
                        color: $primaryColor;
                        
                        border-bottom: 1px solid $primaryColor;
                    }
                    
                }
                
            }
            
        }
        
    }
    
}

.videos-slider {
    
    .horizontal-scroll {
        
        > div {
            width: 250px;
            
            iframe {
                width: 100%;
                height: 200px;
            }
            
        }
        
    }
    
}


#movements-popup {
    
    .item-after {
        font-size: 24px;
        color: $primaryColor;
    }
    
}

#movement-tracking {
    
    .toolbar {
        
        height: 40px;
        
        .link:first-child {
            padding-left: 0;
        }
        
    }
    
}

#add-score-sheet,
#add-record-sheet {
    
    height: 580px;
    
    .page-content {
        max-width: 500px;
        margin: auto;
        padding: 10px;
        
        > div {
            margin-bottom: 10px;
        }
        
    }
    
}

.score-input,
.record-input {
    width: 100%;
    
    .score-label,
    .record-label {
        margin-bottom: 10px;
        display: block;
        font-weight: 500;
        color: $primaryColor;
    }
    
    input {
        text-align: center;
    }
    
    .time-input > div {
        flex: 1 1;
        
        input {
            width: 100% !important;
        }
        
    }
    
    &.score-emom  {
        display: flex;
        align-items: center;
        
        input {
            margin: 0;
        }
        
        .score-label {
            margin-left: 10px;
        }
        
    }
    
}

.score-list-wrapper,
.record-list-wrapper {
    
    .caption {
        display: flex;
        align-items: center;
        
        .block-header {
            margin: 0 !important;
        }
        
    }
    
    .list {
        margin-top: 10px;
    }
    
}

.history-list {
    
    background: #fff;
    box-shadow: 0px 4px 103px rgba(50, 50, 71, 0.01), 0px 4px 59px rgba(50, 50, 71, 0.06);
    
    .media-item {
        border-bottom: 1px solid $lightGrey;
        
        &:last-child {
            border: none;
        }
        
        .item-title {
            color: $primaryColor;
            font-size: 15px;
            font-weight: 500 !important;
        }
        
        .item-after {
            color: $primaryColor;
            font-weight: 600 !important;
        }
        
        .item-text {
            color: $grayColor;
            max-height: 50px;
            overflow: auto;
            text-overflow: unset;
            -webkit-line-clamp: unset;
        }
        
    }
    
}

//desktop
@media (min-width: 768px) {

}

//smartphone
@media (max-width: 768px) {
    

}