$menuBgColor: #fff;

:root {
    --f7-menu-bg-color: #{$menuBgColor};
    --f7-menu-list-selected-bg-color: #{$menuBgColor};
    --f7-menu-text-color: #{$primaryColor};
    --f7-menu-dropdown-padding-vertical: 0;
    --f7-menu-item-padding-horizontal: 10px;
    --f7-menu-dropdown-item-height: 50px;
    --f7-menu-font-size: 18px;
    --f7-menu-dropdown-border-radius: 6px;
}

.menu-item {
    
    z-index: 10;
    
    &.active-state:not(.menu-item-dropdown-opened) {
      background-color: $menuBgColor;
    }
    
    .menu-item-content {
        padding-left: 0;
        overflow: visible;
    }
    
}

.menu-dropdown {
    
    height: 0;
    
    .menu-dropdown-content {
        
        max-height: 50vh;
        box-shadow: 0px 4px 26px rgba(206, 211, 215, 0.28);
        border-radius: var(--f7-menu-dropdown-border-radius) !important;
        
        .menu-dropdown-item {
            border-bottom: 1px solid $lightGrey;
            cursor: pointer;
            
            &:last-child {
                border-bottom: none;
            }
            
        }
        
    }
    
}

.menu-item-dropdown .menu-item-content:after {
    content: '\e5cf';
    font-family: 'Material Icons';
    background: transparent;
    width: 20px;
    height: 20px;
    right: 0;
    line-height: 1;
    bottom: auto;
    top: 50%;
    transform: translate(100%, -50%);
}

.menu-item-dropdown-opened .menu-item-content:after {
    opacity: 1;
    content: '\e5ce';
}