:root {
    --f7-block-margin-vertical: 20px;
    --f7-block-title-medium-font-size: 18px;
    --f7-block-title-font-weight: 500;
    --f7-block-title-medium-font-weight: 600;
    --f7-block-title-large-font-weight: 600;
    --f7-block-strong-border-color: transparent;
    --f7-block-header-text-color: #{$grayColor};
    --f7-block-footer-text-color: #{$textColor};
    --f7-block-title-text-color: #{$textColor};
    --f7-block-title-large-text-color: var(--f7-block-title-text-color);
    --f7-block-title-medium-text-color: var(--f7-block-title-text-color);
}

.block {
    
    .segment {
        background: $segmentBackgroundColor;
        border-radius: 12px;
        padding: 20px;
        
        .block-title {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 15px;
        }
        
        .card {
            margin: 0;
        }
        
    }
    
}

.block-header {
    margin-left: 0;
    text-transform: uppercase;
    font-weight: 600;
}