$primaryColor: #19294B;
$secondaryColor: #0054A8;
$accentOneColor: #2A8DF0;
$accentTwoColor: #FF8A00;
$textColor: $primaryColor;
$redColor: #DE626D;
$greenColor: #2ecc71;
$yellowColor: #ffcc00;
$grayColor: #8B93A4;
$lightGrey: #EBEFF8;
$pageBackground: #f9f9f9;
$segmentBackgroundColor: #fff;
$hoverBackgroundColor: $secondaryColor;

$borderRadius: 6px;

$primaryColorRed: red($primaryColor);
$primaryColorGreen: green($primaryColor);
$primaryColorBlue: blue($primaryColor);
$primaryShade: darken($primaryColor, 8%);
$primaryTint: lighten($primaryColor, 8%);

$secondaryColorRed: red($secondaryColor);
$secondaryColorGreen: green($secondaryColor);
$secondaryColorBlue: blue($secondaryColor);
$secondaryShade: darken($secondaryColor, 8%);
$secondaryTint: lighten($secondaryColor, 8%);

$redColorRed: red($redColor);
$redColorGreen: green($redColor);
$redColorBlue: blue($redColor);
$redShade: darken($redColor, 8%);
$redTint: lighten($redColor, 8%);

$greenColorRed: red($greenColor);
$greenColorGreen: green($greenColor);
$greenColorBlue: blue($greenColor);
$greenShade: darken($greenColor, 8%);
$greenTint: lighten($greenColor, 8%);

$grayColorRed: red($grayColor);
$grayColorGreen: green($grayColor);
$grayColorBlue: blue($grayColor);
$grayShade: darken($grayColor, 8%);
$grayTint: lighten($grayColor, 8%);

$toolbarWidthDesktop: 120px;
$mainToolbarIconFontsize: 22px;
$mainToolbarIconFontsizeDesktop: 30px;
$paddingVertical: 10px;

$primaryDropShadow: 0px 3px 4px rgba(0, 0, 0, 0.1), -1px -1px 3px rgba(0, 0, 0, 0.1);

@import '@material-design-icons/font';

:root {
  --f7-theme-color: #{$primaryColor};
  --f7-theme-color-rgb: #{$primaryColorRed}, #{$primaryColorGreen}, #{$primaryColorBlue};
  --f7-theme-color-shade: #{$primaryShade};
  --f7-theme-color-tint: #{$hoverBackgroundColor};  
  --f7-page-bg-color: #fff;
  --f7-text-color: #{$textColor};
  --f7-font-family: 'Poppins', sans-serif; 
}

.color-blue {
  --f7-theme-color:  #{$secondaryColor};
  --f7-theme-color-rgb: #{$secondaryColorRed}, #{$secondaryColorGreen}, #{$secondaryColorBlue};
  --f7-theme-color-shade: #{$secondaryShade};
  --f7-theme-color-tint: #{$secondaryTint};
}


.color-red {
  --f7-theme-color: #{$redColor};
  --f7-theme-color-rgb: #{$redColorRed}, #{$redColorGreen}, #{$redColorBlue};
  --f7-theme-color-shade: #{$redShade};
  --f7-theme-color-tint: #{$redTint};
}

.color-green {
  --f7-theme-color: #{$greenColor};
  --f7-theme-color-rgb: #{$greenColorRed}, #{$greenColorGreen}, #{$greenColorBlue};
  --f7-theme-color-shade: #{$greenShade};
  --f7-theme-color-tint: #{$greenTint};
}

.color-gray {
  --f7-theme-color: #{$grayColor};
  --f7-theme-color-rgb: #{$grayColorRed}, #{$grayColorGreen}, #{$grayColorBlue};
  --f7-theme-color-shade: #{$grayShade};
  --f7-theme-color-tint: #{$grayTint};
}

@import 'fonts/poppins.css';
@import 'ios.import';
@import 'general.import';

@import 'global/toolbar.import';
@import 'global/tabs.import';
@import 'global/button.import';
@import 'global/lists.import';
@import 'global/form.import';
@import 'global/panel.import';
@import 'global/popup.import';
@import 'global/block.import';
@import 'global/sortable.import';
@import 'global/toast.import';
@import 'global/dialog.import';
@import 'global/menu.import';
@import 'global/card.import';
@import 'global/notice.import';
@import 'global/popover.import';
@import 'global/common.import';
@import 'global/algolia.import';

@import 'comps/workout-sets.import';

@import 'pages/signup-login.import';
@import 'pages/planner.import';
@import 'pages/library.import';
@import 'pages/account.import';
@import 'pages/view-workout.import';