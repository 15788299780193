.ais-Pagination {
    
    .ais-Pagination-list {
        padding-inline-start: 0;
        margin: 0;
        
        .ais-Pagination-item {
            border-radius: 6px;
            margin: 0 1px;
        }
        
        .ais-Pagination-item--selected {
            background: $lightGrey;
            
        }
        
    }
    
}



.ais-Menu-list {
    margin: 0;
    list-style: none;
    display: flex;
    padding-inline: 0;
    
    > .ais-Menu-item {
        height: 28px;
        margin: 0 20px 0 0;
        background: #fff;
        
        &.ais-Menu-item--selected {
            background: $lightGrey;
        }
        
        .ais-Menu-link {
            color: $primaryColor;
        }
        
        .ais-Menu-label {
            text-transform: capitalize;
            font-weight: 400;
        }
        
        .ais-Menu-count {
            margin: 0 0 0 10px;
            font-size: 11px;
            color: $grayColor;
        }
        
    }
}

.algolia-searchbox {
    
    background: #fff;
    position: relative;
    color: $grayColor;
    
    .icon {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        font-size: 19px;
        color: $grayColor
    }
    
    input {
        padding-left: 40px;
        padding-right: 40px;
        color: $primaryColor;
        
        &::placeholder {
          color: var(--f7-searchbar-placeholder-color);
          opacity: 1;
        }
        
    }
    
    .input-clear-button {
        right: 8px;    
        color: $grayColor;
    }
    
}

.ais-RefinementList {
    
    margin-top: 0 !important;
    
    .ais-RefinementList-label {
        display: flex;
        color: $primaryColor;
        margin-left: 5px;
        align-items: center;
        margin-bottom: 10px;
        
        .ais-RefinementList-checkbox {
            border: 2px solid $lightGrey;
            border-radius: 4px;
            margin: 0 5px 0 0;
            text-align: center;
            
            &:checked {
                border-color: $primaryColor;
            }
            
        }
        
        .ais-RefinementList-count {
            color: $grayColor;
            margin-left: 10px;
            background: $lightGrey;
            min-width: 16px;
            height: 20px;
            text-align: center;
            border-radius: 4px;
            font-size: 10px;
            line-height: 20px;
            padding: 0 4px;
        }
        
    }   
    
}

