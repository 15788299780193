:root {
    --f7-list-bg-color: transparent;
    --f7-list-item-border-color: transparent;
    --f7-list-border-color: transparent;
    --f7-list-chevron-icon-color: #{$primaryColor};
    --f7-list-font-size: 14px;
    --f7-list-item-footer-text-color: #{$grayColor};
    --f7-input-focused-border-color: transparent;
    --f7-swipeout-delete-button-bg-color: #{$redColor};
    --f7-list-item-subtitle-text-color: #{$grayColor};
}

.list {
    
    &.no-chevron .item-inner:before,
    li.no-chevron .item-inner:before {
        display: none !important;
    }
    
    .item-content {
        
        &.item-input {
            
            input,
            select,
            textarea {
                border: 1px solid var(--f7-input-outline-border-color);
                background: #fff;
                padding: 8px;
                border-radius: $borderRadius;
            }
            
            .item-title {
                margin-bottom: 5px;
            }
            
            .input-clear-button {
                right: 5px;
            }
            
        }
        
        
        
        &.item-input-focused {
            
            .item-inner {
                                
                input,
                select {
                    border: 1px solid var(--f7-input-outline-focused-border-color);
                }
                
                &:after {
                    display: none !important;
                }
                
            } 
            
        }
        
    }

    &.media-list {
    
        .item-title {
            font-size: 18px;
            font-weight: 600 !important;
        }
        
    }
    
}

:root .item-link {
    
    margin-bottom: 8px;
    
    .item-inner {
    
        &:before {
            color: $grayColor;
            display: inline-block;
            top: 50%;
            right: 10px;
            text-align: center;
            padding-left: 2px;
        }
        
        .item-title {
            font-weight: 400;
        }
    }
    
}



