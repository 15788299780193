:root {

}

.toast {
    
    .toast-button {
        color: #fff;
    }
    
}